<messages>["./ManageMachineClients"]</messages>

<template>
  <v-data-table
    hide-default-footer
    class="elevation-1"
    :headers="headers"
    :items="certs"
    :items-per-page="-1"
    :loading="loading"
    :no-data-text="loading ? $t ('label.loading') : $t ('label.empty')">
    <template #item="props">
      <tr>
        <td class="text-left">
          {{ props.item.id }}
        </td>
        <td class="text-center">
          <span
            class="breakall"
            :title="props.item.distinguishedName.replace(/,/g, ', ').replace(/=/g, ' = ')"
            v-text="props.item.distinguishedName.replace(/,/g, ', ').replace(/=/g, ' = ')"/>
        </td>
        <td class="text-center">
          <span
            class="breakall"
            :title="props.item.acceptedHosts ? props.item.acceptedHosts.replace(/,/g, ', ').replace(/=/g, ' = ') : '-'"
            v-text="props.item.acceptedHosts ? props.item.acceptedHosts.replace(/,/g, ', ').replace(/=/g, ' = ') : '-'"/>
        </td>
        <td>
          <action-buttons
            :value="isActionButtonsActive (props.item.id)"
            :buttons="getActionButtons (props.item)"
            @input="state => setActionButtonsActive (props.item.id) (state)"
            @clicked="processActionButton"/>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
  import ActionButtons from '@/app/core/components/ActionButtons'

  import actionButtonsHelper from '@/app/core/mixins/ActionButtonsHelper'

  export default {
    components: {
      ActionButtons
    },
    mixins: [actionButtonsHelper],

    props: {
      certs: {
        type: Array,
        default: () => []
      },
      loading: Boolean
    },

    data () {
      return {
        dialogMap: {}
      }
    },

    computed: {
      headers () {
        return [
          {
            text: this.$t ('label.id'),
            align: 'left',
            sortable: true,
            value: 'id'
          },
          {
            text: this.$t ('label.distinguishedName'),
            align: 'center',
            sortable: true,
            value: 'distinguishedName'
          },
          {
            text: this.$t ('label.acceptedHosts'),
            align: 'center',
            sortable: true,
            value: 'acceptedHosts'
          },
          {
            text: this.$t ('label.actions'),
            sortable: false,
            value: 'action'
          }
        ]
      }
    },

    methods: {
      getActionButtons (item) {
        return [
          {
            action: 'edit',
            item: item,
            icon: 'edit',
            tooltip: this.$t ('general.button.edit')
          },
          {
            action: 'delete',
            deleteItem: item,
            icon: 'delete',
            tooltip: this.$t ('general.button.delete'),
            color: 'error'
          }
        ]
      },

      processActionButton (button) {
        switch (button.action) {
          case 'delete':
            this.$emit ('delete', button.deleteItem)
            break
          case 'edit':
            this.$emit ('edit', button.item)
            break
          default:
            console.warn ('Unhandled button clicked:', button)
            break
        }
      }
    }
  }
</script>

<style scoped>
  .breakall {
    word-break: normal;
  }
</style>
