<messages>["./ManageMachineClients"]</messages>

<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col
          v-t="'label.sslDescription'"
          cols="12"/>
        <v-col cols="12">
          <create-update-cert v-slot="{on}" :client-id="clientId" @added="updateCerts">
            <v-btn
              v-t="'label.addCert'"
              v-on="on"/>
          </create-update-cert>
        </v-col>
        <v-col cols="12">
          <ssl-cert-table :loading="loadingCerts" :certs="loadedCerts" @delete="onDelete" @edit="editCert"/>
          <create-update-cert v-model="updateDialog" :cert="updateObject" update @added="updateCerts"/>
        </v-col>
      </v-row>
    </v-card-text>
    <base-dialog v-model="deleteDialog" mw1>
      <v-card v-if="deleteItem">
        <v-card-title primary-title>
          <div>
            <div
              class="text-h5"
              v-text="$t ('label.delete', {id: deleteItem.id})"/>
          </div>
        </v-card-title>
        <v-card-text>
          {{ $t ('label.deleteText', {id: deleteItem.id, subject: deleteItem.distinguishedName, acceptedHosts: deleteItem.acceptedHosts}) }}
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            v-t="'general.button.cancel'"
            text
            :disabled="loadingDelete"
            @click="onCancelDelete"/>
          <v-btn
            v-t="'general.button.delete'"
            color="error"
            :loading="loadingDelete"
            @click="onDeleteItem"/>
        </v-card-actions>
      </v-card>
    </base-dialog>
  </v-card>
</template>

<script>
  import {mapActions} from 'vuex'

  import BaseDialog from '@/app/core/components/BaseDialog'

  import SslCertTable from './SSLCertTable'
  import CreateUpdateCert from './CreateUpdateCert'

  export default {

    components: {
      SslCertTable,
      CreateUpdateCert,
      BaseDialog
    },

    props: {
      clientId: {
        type: Number,
        required: true
      }
    },

    data () {
      return {
        loadedCerts: [],
        loadingCerts: false,
        loadingDelete: false,
        deleteDialog: false,
        deleteItem: null,
        updateObject: {acceptedHosts: ''},
        updateDialog: false
      }
    },

    watch: {
      clientId () {
        this.loadCerts ()
      }
    },

    created () {
      this.loadCerts ()
      this.resetUpdate ()
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      loadCerts () {
        this.loadingCerts = true
        this.fetchData ({
          op: 'machine-clients/list-ssl-certificates',
          params: {
            id: this.clientId
          },
          cb: data => {
            this.loadedCerts = data.sslData
          },
          cbFinal: () => {
            this.loadingCerts = false
          }
        })
      },

      editCert (cert) {
        this.updateObject = cert
        this.updateDialog = true
      },

      resetUpdate () {
        this.updateObject = {acceptedHosts: ''}
        this.updateDialog = false
      },

      updateCerts (certs) {
        this.resetUpdate ()
        this.loadedCerts = certs
      },

      onDelete (deleteItem) {
        this.deleteDialog = true
        this.deleteItem = deleteItem
      },

      onDeleteItem () {
        this.loadingDelete = true
        this.fetchData ({
          op: 'machine-clients/delete-ssl-certificate',
          params: {
            id: this.deleteItem.id
          },
          cb: data => {
            this.updateCerts (data.sslData)
            this.deleteItem = null
            this.deleteDialog = false
          },
          cbFinal: () => {
            this.loadingDelete = false
          }
        })
      },

      onCancelDelete () {
        this.deleteItem = null
        this.deleteDialog = false
      }
    }

  }
</script>
