<messages>["./OTPManagement"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <v-row>
    <v-col cols="12">
      <v-data-table
        class="elevation-1"
        :headers="tableHeaders"
        :items="value.otps"
        :custom-sort="sortOtps"
        :footer-props="footerProps"
        :no-data-text="noDataText (false)">
        <template #item="props">
          <tr>
            <td class="text-left monospaced text-subtitle-1 font-weight-medium">
              {{ props.item.otp }}
            </td>
            <td class="text-left">
              <v-icon color="green">
                {{ props.item.used ? '' : 'check_circle' }}
              </v-icon>
            </td>
            <td class="text-left">
              {{ formatDate (props.item.usageDate) }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {footerProps, noDataText, noResultsText} from '@/app/core/mixins/PaginationComponent'

  export default {
    name: 'OTPTable',

    props: {
      value: {
        type: Object,
        required: true
      }
    },

    computed: {
      // default footer properties, which can be used for `VDataTable` component
      footerProps,

      tableHeaders () {
        return [
          {
            text: this.$t ('details.header.otp'),
            value: 'otp',
            align: 'left'
          },
          {
            text: this.$t ('details.header.unused'),
            value: 'unused',
            align: 'left'
          },
          {
            text: this.$t ('details.header.date'),
            value: 'date',
            align: 'left'
          }
        ]
      }
    },

    /**
     * Sort the given array of OTP data by the specified column
     *
     * @param {Array} otps          the OTP data to sort
     * @param {String} column       the name of the column to sort by
     * @param {Boolean} desc        will sort descendingly if true, ascendingly
     *                              if false and not at all if null
     */
    methods: {
      // default text properties, which can be used for `VDataTable` component
      noDataText,
      noResultsText,

      sortOtps (otps, column, desc) {
        if (desc == null) return otps

        const order = (desc ? -1 : 1)

        switch (column) {
          case 'otp':
            return otps.sort ((o1, o2) => order * o1.otp.localeCompare (o2.otp))

          case 'unused':
            return otps.sort ((o1, o2) => order * (+o1.used - +o2.used))

          case 'date':
            return otps.sort ((o1, o2) => order * (o1.usageDate - o2.usageDate))

          default:
            console.warn ('sorting by ' + column + ' is not supported')
            return otps
        }
      }
    }
  }
</script>
