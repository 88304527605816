<messages>["./OTPManagement"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <div
              v-t="'create.title'"
              class="text-subtitle-1 font-weight-medium text-uppercase"/>
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="selectedBunchSize"
              :items="bunchSizes"
              :label="$t ('create.size')"/>
          </v-col>
          <v-col class="shrink">
            <v-btn
              v-t="'create.submit'"
              small
              @click="onCreate"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div
              v-t="'bunches.title'"
              class="text-subtitle-1 font-weight-medium text-uppercase"/>
          </v-col>
          <v-col cols="12">
            <p v-t="'bunches.intro'"/>
          </v-col>
          <v-col cols="12">
            <v-data-table
              class="elevation-1"
              hide-default-footer
              :headers="tableHeaders"
              :items="bunches"
              :items-per-page="bunchesPagination.rowsPerPage"
              :sort-by.sync="bunchesPagination.sortBy"
              :sort-desc.sync="bunchesPagination.descending"
              :custom-sort="sortBunches"
              :no-data-text="$t ('bunches.noData')">
              <template #item="props">
                <tr>
                  <td class="text-right">
                    {{ props.item.sequenceNumber }}
                  </td>
                  <td class="text-center">
                    <v-icon :color="props.item.active ? 'green' : 'red'">
                      {{ props.item.active ? 'check_circle' : 'cancel' }}
                    </v-icon>
                  </td>
                  <td class="text-right">
                    {{ props.item.size }}
                  </td>
                  <td class="text-left">
                    {{ formatDate (props.item.creationDate, true) }}
                  </td>
                  <td class="text-left">
                    {{ formatDate (props.item.deactivationDate, true) }}
                  </td>
                  <td class="text-left pl-4">
                    <action-buttons
                      :value="isActionButtonsActive (
                        props.item.sequenceNumber)"
                      :buttons="getActions (props.item)"
                      @input="state => setActionButtonsActive (
                        props.item.sequenceNumber)(state)"
                      @clicked="onAction"/>
                    <a
                      :id="'bunch-' + props.item.sequenceNumber"
                      :href="getDownloadLink (props.item.sequenceNumber)"
                      hidden/>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <base-dialog
      v-model="showDetails"
      mw0
      close-on-esc
      :scrollable="true">
      <v-card>
        <v-card-title primary-title>
          <div>
            <div
              v-t="'details.title'"
              class="text-h5 mb-6"/>
            <div>
              <otp-bunch-details
                v-if="isBunchLoaded"
                v-model="loadedBunch"/>
            </div>
          </div>
        </v-card-title>
        <v-card-text>
          <otp-table
            v-if="isBunchLoaded"
            v-model="loadedBunch"/>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            v-t="'general.button.close'"
            @click="showDetails = false"/>
        </v-card-actions>
      </v-card>
    </base-dialog>
  </div>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapActions, mapGetters} from 'vuex'

  import ActionButtons from '@/app/core/components/ActionButtons'
  import actionButtonsHelper from '@/app/core/mixins/ActionButtonsHelper'
  import BaseDialog from '@/app/core/components/BaseDialog'
  import OtpBunchDetails from './OTPBunchDetails'
  import OtpTable from './OTPTable'

  export default {
    name: 'OTPManagement',

    components: {
      ActionButtons,
      BaseDialog,
      OtpBunchDetails,
      OtpTable
    },

    mixins: [actionButtonsHelper],

    props: {
      clientId: {
        type: Number,
        default: undefined
      }
    },

    data () {
      return {
        bunches: [],
        loadedBunch: null,
        bunchesPagination: {
          sortBy: 'number',
          descending: true,
          rowsPerPage: -1
        },
        bunchSizes: [50, 500, 5000, 15000],
        selectedBunchSize: 50,
        showDetails: false
      }
    },

    computed: {
      ...mapGetters ({
        operationLink: 'request/operationLink'
      }),

      isBunchLoaded () {
        return this.loadedBunch !== null
      },

      tableHeaders () {
        return [
          {
            text: this.$t ('bunches.header.number'),
            value: 'number',
            align: 'right',
            width: '5%'
          },
          {
            text: this.$t ('bunches.header.active'),
            value: 'active',
            align: 'center',
            width: '5%'
          },
          {
            text: this.$t ('bunches.header.size'),
            value: 'size',
            align: 'right',
            width: '5%'
          },
          {
            text: this.$t ('bunches.header.created'),
            value: 'created',
            width: '40%'
          },
          {
            text: this.$t ('bunches.header.deactivated'),
            value: 'deactivated',
            width: '40%'
          },
          {
            text: this.$t ('bunches.header.actions'),
            value: 'actions',
            sortable: false,
            width: '5%'
          }
        ]
      }
    },

    watch: {
      clientId () {
        this.loadBunches ()
      }
    },

    created () {
      this.loadBunches ()
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      /**
       * Get the action button actions for the given item.
       *
       * @param {Object} item     the item to get the actions for
       */
      getActions (item) {
        return [
          {
            action: 'view',
            icon: 'visibility',
            tooltip: this.$t ('bunches.tooltip.view'),
            item
          },
          {
            action: 'download',
            icon: 'get_app',
            tooltip: this.$t ('bunches.tooltip.download'),
            disabled: !item.active,
            item
          },
          {
            action: 'deactivate',
            icon: 'block',
            tooltip: this.$t ('bunches.tooltip.deactivate'),
            color: 'error',
            disabled: !item.active,
            item
          }
        ]
      },

      /**
       * Load all OTP bunches of the selected or acting client.
       */
      loadBunches () {
        this.fetchData ({
          op: 'otp/listBunches',
          params: {
            id: this.clientId
          },
          cb: data => {
            this.bunches = data.list
              .sort ((b1, b2) => b1.creationDate - b2.creationDate)
          }
        })
      },

      /**
       * Load one OTP bunch.
       *
       * @param {Number} sequenceNumber     the sequence number of the bunch
       *                                    to load
       */
      loadBunch (sequenceNumber) {
        this.fetchData ({
          op: 'otp/loadBunch',
          params: {
            sequenceNumber,
            id: this.clientId
          },
          cb: data => {
            this.loadedBunch = data.bunch
          }
        })
      },

      /**
       * Deactivate an OTP bunch.
       *
       * @param {Number} sequenceNumber     the sequence number of the bunch
       *                                    to load
       */
      deactivateBunch (sequenceNumber) {
        this.fetchData ({
          op: 'otp/deactivateBunch',
          params: {
            sequenceNumber,
            id: this.clientId
          },
          cb: () => {
            this.loadedBunch = null
            this.loadBunches ()
          }
        })
      },

      /**
       * Create a new OTP bunch for the selected or acting client.
       */
      createBunch () {
        this.fetchData ({
          op: 'otp/createBunch',
          params: {
            size: this.selectedBunchSize,
            id: this.clientId
          },
          cb: () => {
            this.loadBunches ()
          }
        })
      },

      /**
       * Trigger the download of an OTP bunch.
       *
       * @param {Number} sequenceNumber     the sequence number of the bunch
       *                                    to download
       */
      downloadBunch (sequenceNumber) {
        document.getElementById ('bunch-' + sequenceNumber).click ()
      },

      /**
       * Sort the given array of bunches by the specified column.
       *
       * @param {Array} bunches       the bunches to sort
       * @param {String} column       the name of the column to sort by
       * @param {Boolean} desc        will sort descendingly if true,
       *                              ascendingly if false and not at all if
       *                              null
       */
      sortBunches (bunches, [column, ...secColumn], [desc, ...secDesc]) {
        if (desc == null) return bunches

        const order = (desc ? -1 : 1)

        switch (column) {
          case 'number':
            return bunches.sort ((b1, b2) =>
              order * (b1.sequenceNumber - b2.sequenceNumber))

          case 'active':
            return bunches.sort ((b1, b2) => order * (+b1.active - +b2.active))

          case 'size':
            return bunches.sort ((b1, b2) => order * (b1.size - b2.size))

          case 'created':
            return bunches.sort ((b1, b2) =>
              order * (b1.creationDate - b2.creationDate))

          case 'deactivated':
            return bunches.sort ((b1, b2) =>
              order * (b1.deactivationDate - b2.deactivationDate))

          default:
            console.warn ('sorting by ' + column + ' is not supported')
            return bunches
        }
      },

      /**
       * Create a new OTP bunch with the selected size.
       */
      onCreate () {
        this.createBunch ()
      },

      /**
       * Handle an action button click.
       *
       * @param {Object} button     the button that has been clicked
       */
      onAction (button) {
        const bunch = button.item

        switch (button.action) {
          case 'view':
            this.loadBunch (bunch.sequenceNumber)
            this.showDetails = true
            break

          case 'download':
            this.downloadBunch (bunch.sequenceNumber)
            break

          case 'deactivate':
            this.deactivateBunch (bunch.sequenceNumber)
            break

          default:
            console.warn ('unexpected action "' + button.action + '"')
        }
      },

      /**
       * Create the download link for an OTP bunch.
       *
       * @param {Number} sequenceNumber     the sequence number of the bunch
       *                                    to download
       * @return {String}                   the download link
       */
      getDownloadLink (sequenceNumber) {
        return this.operationLink ({
          op: 'otp/listOtps',
          params: {
            sequenceNumber,
            id: this.clientId
          }
        })
      }
    }
  }
</script>
