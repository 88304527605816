<messages>["./ManageMachineClients"]</messages>

<template>
  <v-data-table
    hide-default-footer
    class="elevation-1"
    :headers="headers"
    :items="addresses"
    :items-per-page="-1"
    :loading="loading"
    :no-data-text="loading ? $t ('label.loading') : $t ('label.empty')">
    <template #item="props">
      <tr>
        <td class="text-left">
          {{ props.item.emailAddress }}
        </td>
        <td class="text-center">
          <span
            class="wrap"
            :title="formatDate (props.item.versionFrom, true)"
            v-text="formatDate (props.item.versionFrom, true)"/>
        </td>
        <td class="text-center">
          <span
            class="wrap"
            :title="props.item.versionTo ? formatDate (props.item.versionTo, true) : '-'"
            v-text="props.item.versionTo ? formatDate (props.item.versionTo, true) : '-'"/>
        </td>
        <td class="text-center">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon :color="getIcon (props.item).color" v-on="on">
                {{ getIcon(props.item).icon }}
              </v-icon>
            </template>
            <span>{{ getIcon(props.item).tooltip }}</span>
          </v-tooltip>
        </td>
        <td>
          <action-buttons
            :value="isActionButtonsActive (props.item.id)"
            :buttons="getActionButtons (props.item)"
            @input="state => setActionButtonsActive (props.item.id) (state)"
            @clicked="processActionButton"/>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
  import ActionButtons from '@/app/core/components/ActionButtons'

  import actionButtonsHelper from '@/app/core/mixins/ActionButtonsHelper'

  export default {
    components: {
      ActionButtons
    },
    mixins: [actionButtonsHelper],

    props: {
      addresses: {
        type: Array,
        default: () => []
      },
      loading: Boolean
    },

    data () {
      return {}
    },

    computed: {
      headers () {
        return [
          {
            text: this.$t ('label.emailAddress'),
            align: 'left',
            sortable: true,
            value: 'emailAddress'
          },
          {
            text: this.$t ('label.validFrom'),
            align: 'center',
            sortable: true,
            value: 'versionFrom'
          },
          {
            text: this.$t ('label.validTo'),
            align: 'center',
            sortable: true,
            value: 'versionTo'
          },
          {
            text: this.$t ('label.validity'),
            align: 'center',
            sortable: false,
            value: 'validity'
          },
          {
            text: this.$t ('label.actions'),
            sortable: false,
            value: 'action'
          }
        ]
      }
    },

    methods: {
      getActionButtons (item) {
        return [
          {
            action: 'edit',
            item: item,
            disabled: !!item.versionTo,
            icon: 'edit',
            tooltip: this.$t ('general.button.edit')
          },
          {
            action: 'delete',
            deleteItem: item,
            disabled: !!item.versionTo,
            icon: 'delete',
            tooltip: this.$t ('general.button.delete'),
            color: 'error'
          }
        ]
      },

      getIcon (key) {
        const now = new Date ().getTime ()
        const from = key.versionFrom
        const to = key.versionTo

        if (from < now && (!to || to > now)) {
          return {
            icon: 'check_circle',
            color: 'success',
            tooltip: this.$t ('label.valid')
          }
        } else {
          return {
            icon: 'cancel',
            color: 'error',
            tooltip: this.$t ('label.invalid')
          }
        }
      },

      processActionButton (button) {
        switch (button.action) {
          case 'delete':
            this.$emit ('delete', button.deleteItem)
            break
          case 'edit':
            this.$emit ('edit', button.item.emailAddress)
            break
          default:
            console.warn ('Unhandled button clicked:', button)
            break
        }
      }
    }
  }
</script>
