<messages>["./ManageMachineClients"]</messages>

<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col
          v-t="'label.pgpDescription1'"
          cols="12"/>
        <v-col
          v-t="'label.pgpDescription2'"
          cols="12"/>
        <v-col
          v-t="'label.pgpDescription3'"
          cols="12"/>
        <v-col cols="12">
          <v-row>
            <v-col>
              <create-key
                v-slot="{on}"
                :client-id="clientId"
                @added="updateKeys">
                <v-btn
                  v-t="'label.addPgpKey'"
                  v-on="on"/>
              </create-key>
            </v-col>
            <v-col>
              <gateway-pgp-key/>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <p-g-p-key-table :keys="loadedKeys" :loading="loadingKeys" @delete="onDelete"/>
        </v-col>
      </v-row>
    </v-card-text>
    <base-dialog v-model="deleteDialog" mw1>
      <v-card v-if="deleteItem">
        <v-card-title primary-title>
          <div>
            <div
              class="text-h5"
              v-text="$t ('label.deletePGP', {id: deleteItem.keyId})"/>
          </div>
        </v-card-title>
        <v-card-text>
          {{ $t ('label.deleteTextPGP', {id: deleteItem.keyId}) }}
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            v-t="'general.button.cancel'"
            text
            :disabled="loadingDelete"
            @click="onCancelDelete"/>
          <v-btn
            v-t="'general.button.delete'"
            color="error"
            :loading="loadingDelete"
            @click="onDeleteItem"/>
        </v-card-actions>
      </v-card>
    </base-dialog>
  </v-card>
</template>

<script>
  import {mapActions} from 'vuex'

  import BaseDialog from '@/app/core/components/BaseDialog'

  import PGPKeyTable from './PGPKeyTable'
  import CreateKey from './CreatePGPKey'
  import GatewayPgpKey from './GatewayPGPKey'

  export default {

    components: {
      PGPKeyTable,
      CreateKey,
      GatewayPgpKey,
      BaseDialog
    },

    props: {
      clientId: {
        type: Number,
        required: true
      }
    },

    data () {
      return {
        loadedKeys: [],
        loadingKeys: false,
        deleteDialog: false,
        deleteItem: null,
        loadingDelete: false
      }
    },

    watch: {
      clientId () {
        this.loadKeys ()
      }
    },

    created () {
      this.loadKeys ()
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      updateKeys (keys) {
        this.loadedKeys = keys.map ((item, idx) => ({...item, id: idx + 1}))
      },

      loadKeys () {
        this.loadingKeys = true
        this.fetchData ({
          op: 'machine-clients/list-pgp-keys',
          params: {
            id: this.clientId
          },
          cb: data => {
            this.updateKeys (data.pgpData)
          },
          cbFinal: () => {
            this.loadingKeys = false
          }
        })
      },

      onDelete (deleteItem) {
        this.deleteDialog = true
        this.deleteItem = deleteItem
      },

      onDeleteItem () {
        this.loadingDelete = true
        this.fetchData ({
          op: 'machine-clients/delete-pgp-key',
          params: {
            id: this.clientId,
            stringId: this.deleteItem.keyId
          },
          cb: data => {
            this.updateKeys (data.pgpData)
            this.deleteItem = null
            this.deleteDialog = false
          },
          cbFinal: () => {
            this.loadingDelete = false
          }
        })
      },

      onCancelDelete () {
        this.deleteItem = null
        this.deleteDialog = false
      }
    }

  }
</script>
