<messages>["./Input"]</messages>

<template>
  <multi-tag-input
    ref="input"
    v-model="domains"
    :label="name"
    :hint="$t ('label.domainHint')"
    :error-map="{idnDomain: 'general.invalid.domainName'}"
    :validation-prop="validationProp"
    :additional-error-messages="errorMessages"
    @blur="blur"
    @reset="reset"/>
</template>

<script>
  import {toASCII as punycodeToASCII} from 'punycode/punycode.es6'

  import {idnDomain, fqDomain} from '@/app/validators'
  import MultiTagInput from './MultiTagInput'

  export default {
    name: 'MultiDomainInput',

    components: {
      MultiTagInput
    },

    props: {
      value: {
        type: Array,
        default: () => []
      },
      fullyQualified: {
        type: Boolean,
        default: false
      },
      name: {
        type: String,
        default: undefined
      },
      errorMessages: {
        type: Array,
        default: () => []
      }
    },

    computed: {
      validationProp () {
        return {
          idnDomain: (value) => {
            const idn = punycodeToASCII (value)
            return this.fullyQualified
              ? idnDomain (idn) || fqDomain (idn)
              : idnDomain (idn)
          }
        }
      },

      domains: {
        get () {
          return this.value
        },
        set (newVal) {
          this.$emit ('input', newVal.map (this.normalize).filter (this.onlyUnique))
        }
      }
    },

    methods: {
      normalize (d) {
        let n = d

        if (d.split ('').pop () === '.') {
          n = d.slice (0, d.length - 1)
        }

        return punycodeToASCII (n)
      },

      onlyUnique (value, index, self) {
        return self.indexOf (value) === index
      },

      blur () {
        this.$emit ('blur')
      },
      reset () {
        this.$emit ('reset')
      },
      focus () {
        this.$refs.input.focus ()
      },
      /**
       * Check whether the form has any errors, i.e., contains at least one
       * field with invalid data that is flagged "dirty".
       *
       * @return {Boolean}      true if so
       */
      hasErrors () {
        return this.$refs.input.hasErrors ()
      },

      /**
       * Check whether the form data is valid, i.e., all fields contain valid
       * values.
       *
       * @return {Boolean}      true if so
       */
      isValid () {
        return this.$refs.input.isValid ()
      },

      /**
       * Validate the given field.
       *
       * @param {Object} field      the meta data object identifying the field
       *                            to be validated
       */
      doValidate () {
        return this.$refs.input.doValidate ()
      }
    }
  }
</script>
