<messages>["./ManageMachineClients"]</messages>

<template>
  <base-dialog
    v-model="addDialog"
    mw1
    persistent>
    <template #activator="{ on }">
      <slot :on="on"/>
    </template>
    <v-card flat>
      <v-card-title primary-title>
        <div>
          <div
            class="text-h5"
            v-text="$t (update ? 'label.editEmail' : 'label.addEmail')"/>
        </div>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model.trim="newEmail"
              name="email"
              label="E-Mail"
              :error-messages="validationErrors(
                'newEmail',
                {
                  required: 'error.requiredEmail',
                  email: 'general.invalid.email'
                })"
              single-line
              @blur="$v.newEmail.$touch()"/>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <p v-if="errorMsg" class="red--text">
          {{ $t (errorMsg) }}
        </p>
        <v-btn
          v-t="'general.button.cancel'" text :disabled="isLoading"
          @click="onCancel"/>
        <v-btn
          v-t="'label.save'" color="primary" :loading="isLoading"
          @click="save"/>
      </v-card-actions>
    </v-card>
  </base-dialog>
</template>

<script>
  import {mapActions} from 'vuex'

  import {required} from 'vuelidate/lib/validators'
  import {email} from '@/app/validators'

  import validationMixins from '@/app/core/mixins/ValidationHelper'

  import BaseDialog from '@/app/core/components/BaseDialog'

  export default {

    components: {
      BaseDialog
    },

    mixins: [validationMixins],

    props: {
      clientId: {
        type: Number,
        default: 0
      },
      update: {
        type: Boolean,
        default: false
      },
      email: {
        type: String,
        default: ''
      },
      value: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        isLoading: false,
        addDialog: false,
        newEmail: '',
        errorMsg: ''
      }
    },

    validations: {
      newEmail: {required, email}
    },

    watch: {
      value () {
        this.addDialog = this.value
        if (this.update) {
          this.newEmail = this.email
        } else {
          this.newEmail = ''
        }
        this.errorMsg = ''
      },
      addDialog () {
        this.$emit ('input', this.addDialog)
        if (this.update) {
          this.newEmail = this.email
        } else {
          this.newEmail = ''
        }
        this.errorMsg = ''
      }
    },

    created () {
      if (this.update) {
        this.newEmail = this.email
      }
      this.addDialog = this.value
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      onCancel () {
        this.addDialog = false
        this.newEmail = ''
        this.errorMsg = ''
        this.$emit ('cancel')
      },
      save () {
        if (!this.$v.$invalid) {
          this.isLoading = true
          this.fetchData ({
            op: this.update
              ? 'machine-clients/edit-email'
              : 'machine-clients/add-email',
            params: {
              id: this.clientId,
              stringId: this.newEmail,
              ...this.update ? {oldEmail: this.email} : {}
            },
            cb: data => {
              this.$emit ('added', data.emailData)
              this.onCancel ()
            },
            cbError: (data, next) => {
              const result = data.result.find (item =>
                (item.code === 'error/datamgmt/objexists' ||
                  item.code === 'error/datamgmt/value'))

              if (!result) {
                return next (data)
              }

              this.errorMsg = 'label.error.emailAlreadyExists'
            },
            cbFinal: () => {
              this.isLoading = false
            }
          })
        } else {
          this.$v.$touch ()
        }
      }
    }
  }
</script>
