<messages>["./components/ManageMachineClients"]</messages>

<template>
  <base-layout mw1>
    <v-col>
      <v-card>
        <v-card-title primary-title>
          <v-col cols="12">
            <div>
              <div
                v-t="'label.title.machineClients'"
                class="text-h5"/>
              <div
                v-t="'label.title.machineClientsSub'"
                class="cgwng-subheading"/>
            </div>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col v-show="hasSubClients" cols="12" sm="6">
              <client-select v-model="clientId" for-view/>
            </v-col>
          </v-row>
          <v-tabs
            v-if="clientId !== 0"
            v-model="active"
            slider-color="primary">
            <v-tab
              v-for="n in tabs"
              :key="n.name"
              ripple>
              {{ n.name }}
            </v-tab>
            <v-tab-item
              v-for="n in tabs"
              :key="n.name">
              <v-card flat>
                <component :is="n.is" :client-id="clientId"/>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
      </v-card>
    </v-col>
  </base-layout>
</template>

<script>
  import {mapGetters} from 'vuex'

  import BaseLayout from '@/app/core/components/BaseLayout'
  import ClientSelect from '@/app/core/components/ClientSelect'

  import ManageSSL from './components/ManageSSL'
  import ManagePGP from './components/ManagePGP'
  import ManageEMail from './components/ManageEMail'
  import ManageATP from './components/ManageATP.vue'
  import ManageOTP from './components/ManageOTP/OTPManagement.vue'

  export default {
    components: {
      BaseLayout,
      ClientSelect
    },

    data () {
      return {
        active: null,
        clientId: 0
      }
    },

    computed: {
      ...mapGetters ({
        actingClientId: 'auth/actingClientId',
        hasSubClients: 'auth/hasSubClients'
      }),
      tabs () {
        return [
          {
            is: ManageSSL,
            name: this.$t ('tabs.name.manageSSL')
          },
          {
            is: ManagePGP,
            name: this.$t ('tabs.name.managePGP')
          },
          {
            is: ManageEMail,
            name: this.$t ('tabs.name.manageEMail')
          },
          {
            is: ManageATP,
            name: this.$t ('tabs.name.manageATP')
          },
          {
            is: ManageOTP,
            name: this.$t ('tabs.name.manageOTP')
          }
        ]
      }
    },

    created () {
      if (!this.clientId && this.actingClientId) {
        this.clientId = this.actingClientId
      }
    }
  }
</script>
