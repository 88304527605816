<messages>["./ManageMachineClients"]</messages>

<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col
          v-t="'label.emailDescription1'"
          cols="12"/>
        <v-col
          v-t="'label.emailDescription2'"
          cols="12"/>
        <v-col cols="12">
          <create-update-e-mail
            v-slot="{on}"
            :client-id="clientId"
            @added="updateEmailTable">
            <v-btn
              v-t="'label.addEmail'"
              v-on="on"/>
          </create-update-e-mail>
        </v-col>

        <v-col cols="12">
          <e-mail-table :loading="loadingEmails" :addresses="loadedEmails" @delete="onDelete" @edit="onEdit"/>
          <create-update-e-mail
            v-if="editDialog"
            v-model="editDialog"
            update
            :client-id="clientId"
            :email="editItem"
            @added="updateEmailTable"
            @cancel="onEdited"/>
        </v-col>
      </v-row>
    </v-card-text>
    <base-dialog v-model="deleteDialog" mw1>
      <v-card v-if="deleteItem">
        <v-card-title primary-title>
          <div>
            <div
              class="text-h5"
              v-text="$t ('label.deleteEmail', {email: deleteItem.emailAddress})"/>
          </div>
        </v-card-title>
        <v-card-text>
          {{ $t ('label.deleteTextEmail', {email: deleteItem.emailAddress}) }}
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            v-t="'general.button.cancel'"
            text
            :disabled="loadingDelete"
            @click="onCancelDelete"/>
          <v-btn
            v-t="'general.button.delete'"
            color="error"
            :loading="loadingDelete"
            @click="onDeleteItem"/>
        </v-card-actions>
      </v-card>
    </base-dialog>
  </v-card>
</template>

<script>
  import {mapActions} from 'vuex'

  import BaseDialog from '@/app/core/components/BaseDialog'

  import EMailTable from './EMailTable.vue'
  import CreateUpdateEMail from './CreateUpdateEmail'

  export default {

    components: {
      EMailTable,
      CreateUpdateEMail,
      BaseDialog
    },

    props: {
      clientId: {
        type: Number,
        required: true
      }
    },

    data () {
      return {
        loadingEmails: false,
        loadedEmails: [],
        deleteDialog: false,
        loadingDelete: false,
        deleteItem: null,
        editItem: null,
        editDialog: false
      }
    },

    watch: {
      clientId () {
        this.loadEmails ()
      }
    },

    created () {
      this.loadEmails ()
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      loadEmails () {
        this.loadingEmails = true
        this.fetchData ({
          op: 'machine-clients/list-emails',
          params: {
            id: this.clientId
          },
          cb: data => {
            this.updateEmailTable (data.emailData)
          },
          cbFinal: () => {
            this.loadingEmails = false
          }
        })
      },
      updateEmailTable (mails) {
        this.loadedEmails = mails.map ((item, idx) => ({
          ...item,
          id: idx + 1
        }))
      },
      onDelete (deleteItem) {
        this.deleteDialog = true
        this.deleteItem = deleteItem
      },
      onDeleteItem () {
        this.loadingDelete = true
        this.fetchData ({
          op: 'machine-clients/delete-email',
          params: {
            id: this.clientId,
            stringId: this.deleteItem.emailAddress
          },
          cb: data => {
            this.updateEmailTable (data.emailData)
            this.deleteItem = null
            this.deleteDialog = false
          },
          cbFinal: () => {
            this.loadingDelete = false
          }
        })
      },
      onEdit (editItem) {
        this.editItem = editItem
        this.editDialog = true
      },
      onEdited () {
        this.editItem = null
        this.editDialog = false
      },
      onCancelDelete () {
        this.deleteItem = null
        this.deleteDialog = false
      }
    }
  }
</script>
