<messages>["./ManageMachineClients"]</messages>
<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <!--
            these fields are a workaround to prevent autocompletion
            of the field (GWNG-863)
          -->
          <input id="email" style="display:none" type="text" name="ffWorkaroundEmail">
          <input id="password" style="display:none" type="password" name="ffWorkaroundPassoword">
          <v-text-field
            v-model="password"
            autocomplete="new-password"
            spellcheck="false"
            :label="$t('label.atp')"
            :error-messages="validationErrors(
              'password', { required: 'label.required' })"
            :append-icon="isATPHidden ? 'visibility' : 'visibility_off'"
            :type="isATPHidden ? 'password' : 'text'"
            @blur="$v.password.$touch ()"
            @click:append="isATPHidden = !isATPHidden"/>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        v-t="'general.button.save'" color="primary" :loading="loading"
        @click="save"/>
    </v-card-actions>
  </v-card>
</template>

<script>
  import {mapActions, mapMutations} from 'vuex'
  import {required} from 'vuelidate/lib/validators'

  import validationMixins from '@/app/core/mixins/ValidationHelper'

  export default {

    mixins: [validationMixins],
    props: {
      clientId: {
        type: Number,
        default: 0
      }
    },

    validations: {
      password: {
        required
      }
    },

    data () {
      return {
        password: '',
        isATPHidden: true,
        loading: false
      }
    },

    methods: {
      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess'
      }),

      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      save () {
        if (this.$v.$invalid) {
          this.$v.$touch ()
        } else {
          this.loading = true
          this.fetchData ({
            op: 'machine-clients/edit-atp',
            params: {
              id: this.clientId,
              stringId: this.password
            },
            cb: data => {
              this.displaySuccessMessage (
                this.$t ('label.savedATP'))
              this.password = ''
              this.$v.$reset ()
            },
            cbFinal: () => {
              this.loading = false
            }
          })
        }
      }
    }
  }
</script>
