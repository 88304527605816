<messages>["./ManageMachineClients"]</messages>

<template>
  <base-dialog
    v-model="addDialog"
    mw1
    persistent>
    <template #activator="{ on }">
      <slot :on="on"/>
    </template>
    <v-card flat>
      <v-card-title primary-title>
        <div>
          <div
            class="text-h5"
            v-text="$t ('label.addCert')"/>
        </div>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col v-if="update" cols="12">
            <v-row>
              <v-col cols="12" sm="6">
                <v-switch
                  v-model="editCert"
                  :label="$t ('label.updateCert')"/>
              </v-col>
              <v-col cols="12" sm="6">
                <v-switch
                  v-model="editHosts"
                  :label="$t ('label.updateHosts')"/>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="!update || editCert" cols="12">
            <file-upload
              v-model="newCert.fileName"
              :mime-types="mimeTypes"
              :error-messages="errors"
              text
              :label="$t ('label.upload')"
              @load="fileLoaded"
              @error="onFileError"/>
          </v-col>
          <v-col v-if="(update && editHosts) || (!update && newCert.cert && !fileError)" cols="12">
            <multi-domain-input
              ref="acceptedHosts"
              v-model="newCert.acceptedHosts"
              :name="$t('label.acceptedHosts')"/>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <p v-if="errorMsg" class="red--text">
          {{ $t (errorMsg) }}
        </p>
        <v-btn
          v-t="'general.button.cancel'" text :disabled="isLoading"
          @click="onCancel"/>
        <v-btn
          v-t="'label.save'" color="primary" :loading="isLoading"
          @click="save"/>
      </v-card-actions>
    </v-card>
  </base-dialog>
</template>

<script>
  import {mapActions} from 'vuex'

  import BaseDialog from '@/app/core/components/BaseDialog'
  import FileUpload from '@/app/core/components/FileUpload/FileUpload'
  import MultiDomainInput from '@/app/core/components/Inputs/MultiDomainInput'

  export default {

    components: {
      FileUpload,
      MultiDomainInput,
      BaseDialog
    },

    props: {
      clientId: {
        type: Number,
        default: 0
      },
      update: {
        type: Boolean,
        default: false
      },
      cert: {
        type: Object,
        default: () => ({
          id: 0,
          acceptedHosts: [],
          cert: null
        })
      },
      value: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        isLoading: false,
        addDialog: false,
        mimeTypes: [
          'application/x-x509-ca-cert',
          'application/x-x509-user-cert',
          'application/pkix-cert'
        ],
        newCert: {
          cert: null,
          fileName: '',
          acceptedHosts: []
        },
        error: false,
        fileError: false,
        editCert: false,
        editHosts: false,
        errorMsg: null
      }
    },

    computed: {
      errors () {
        return this.error && this.newCert.fileName === ''
          ? [this.$t ('label.error.selectFile')]
          : []
      }
    },

    watch: {
      value () {
        this.newCert.acceptedHosts = this.cert.acceptedHosts !== ''
          ? this.cert.acceptedHosts.split (',')
          : []
        this.addDialog = this.value
        this.editHosts = false
        this.editCert = false
      },

      addDialog () {
        this.$emit ('input', this.addDialog)
        this.editHosts = false
        this.editCert = false
      }
    },

    created () {
      if (this.update) {
        this.newCert.acceptedHosts = this.cert.acceptedHosts !== ''
          ? this.cert.acceptedHosts.split (',')
          : []
      }
      this.addDialog = this.value
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      fileLoaded (file) {
        this.newCert.cert = file
        this.errorMsg = null
        this.fileError = false
      },

      removeCert (idx) {
        this.newCerts.splice (idx, 1)
      },

      onFileError () {
        this.fileError = true
      },

      save () {
        // TODO, FIXME: this is a workaround for https://github.com/vuetifyjs/vuetify/issues/5203
        // (if submitted directly from combobox, then the combobox value is not updated yet, so wait...)
        // This workaround can be reverted as soon as the issue is resolved and the Vuetify library is updated
        setTimeout (() => {
          if (!this.$refs.acceptedHosts.isValid () || (this.update && this.editCert &&
            (this.newCert.fileName === '' || this.fileError)) ||
            ((!this.update) && (this.newCert.fileName === '' || this.fileError))) {
            this.error = true
          } else {
            this.error = false
            this.errorMsg = null
            this.isLoading = true
            this.fetchData ({
              op: this.update
                ? 'machine-clients/edit-ssl-certificate'
                : 'machine-clients/add-ssl-certificates',
              params: {
                id: this.update
                  ? this.cert.id
                  : this.clientId,
                createData: this.update
                  ? {
                    cert: this.editCert ? this.newCert.cert : null,
                    acceptedHosts: this.editHosts ? this.newCert.acceptedHosts : null
                  }
                  : [{
                    cert: this.newCert.cert,
                    acceptedHosts: this.newCert.acceptedHosts
                  }]
              },
              cb: data => {
                this.$emit ('added', data.sslData)
                this.reset ()
                this.addDialog = false
              },
              cbCatch: () => {
                this.errorMsg = 'label.error.invalidCertificate'
              },
              cbError: () => {
                this.errorMsg = 'label.error.invalidCertificate'
              },
              cbFinal: () => {
                this.isLoading = false
              }
            })
          }
        })
      },

      reset () {
        this.newCert = {
          cert: null,
          fileName: '',
          acceptedHosts: []
        }
        this.errorMsg = null
        this.error = false
      },

      onCancel () {
        this.addDialog = false
        this.editCert = false
        this.editHosts = false
        this.reset ()
      }
    }

  }
</script>
