var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-dialog',{attrs:{"mw1":"","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_vm._t("default",null,{"on":on})]}}],null,true),model:{value:(_vm.addDialog),callback:function ($$v) {_vm.addDialog=$$v},expression:"addDialog"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',[_c('div',{staticClass:"text-h5",domProps:{"textContent":_vm._s(_vm.$t (_vm.update ? 'label.editEmail' : 'label.addEmail'))}})])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"name":"email","label":"E-Mail","error-messages":_vm.validationErrors(
              'newEmail',
              {
                required: 'error.requiredEmail',
                email: 'general.invalid.email'
              }),"single-line":""},on:{"blur":function($event){return _vm.$v.newEmail.$touch()}},model:{value:(_vm.newEmail),callback:function ($$v) {_vm.newEmail=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"newEmail"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),(_vm.errorMsg)?_c('p',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.$t (_vm.errorMsg))+" ")]):_vm._e(),_c('v-btn',{directives:[{name:"t",rawName:"v-t",value:('general.button.cancel'),expression:"'general.button.cancel'"}],attrs:{"text":"","disabled":_vm.isLoading},on:{"click":_vm.onCancel}}),_c('v-btn',{directives:[{name:"t",rawName:"v-t",value:('label.save'),expression:"'label.save'"}],attrs:{"color":"primary","loading":_vm.isLoading},on:{"click":_vm.save}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }