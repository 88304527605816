<messages>["./OTPManagement"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <v-row align="center">
    <v-col class="shrink mr-4">
      <v-row>
        <v-col class="py-0 pl-6" cols="12">
          <span
            v-t="'details.property.bunch'"
            class="font-weight-medium"/>
        </v-col>
        <v-col class="py-0 pl-6" cols="12">
          <span
            v-t="'details.property.active'"
            class="font-weight-medium"/>
        </v-col>
        <v-col class="py-0 pl-6" cols="12">
          <span
            v-t="'details.property.created'"
            class="font-weight-medium"/>
        </v-col>
        <v-col
          v-if="value.deactivationDate"
          class="py-0 pl-6"
          cols="12">
          <span
            v-t="'details.property.deactivated'"
            class="font-weight-medium"/>
        </v-col>
        <v-col class="py-0 pl-6" cols="12">
          <span
            v-t="'details.property.used'"
            class="font-weight-medium"/>
        </v-col>
      </v-row>
    </v-col>
    <v-col class="shrink">
      <v-row>
        <v-col class="py-0" cols="12">
          <span>{{ value.sequenceNumber }}</span>
        </v-col>
        <v-col class="py-0" cols="12">
          <v-icon
            small
            :color="value.active ? 'green' : 'red'">
            {{ value.active ? 'check_circle' : 'cancel' }}
          </v-icon>
        </v-col>
        <v-col class="py-0" cols="12">
          <span>
            {{ formatDate (value.creationDate) }}
          </span>
        </v-col>
        <v-col class="py-0" cols="12">
          <span>
            {{ formatDate (value.deactivationDate) }}
          </span>
        </v-col>
        <v-col class="py-0" cols="12">
          <span>{{ usedOtps }}/{{ unusedOtps }}</span>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  export default {
    name: 'OTPBunchDetails',

    props: {
      value: {
        type: Object,
        required: true
      }
    },

    computed: {
      usedOtps () {
        return this.value.otps.filter (d => d.used).length
      },

      unusedOtps () {
        return this.value.otps.length - this.usedOtps
      }
    }
  }
</script>
