<messages>["./ManageMachineClients"]</messages>

<template>
  <base-dialog
    v-model="addDialog"
    mw1
    scrollable
    persistent>
    <template #activator="{ on }">
      <slot :on="on"/>
    </template>
    <v-card flat>
      <v-card-title primary-title>
        <div>
          <div
            class="text-h5"
            v-text="$t ('label.addPgpKey')"/>
        </div>
      </v-card-title>
      <v-card-text max-height="300px">
        <v-row>
          <v-col cols="12">
            <code-editor
              v-model="newKey"
              :lines="lines"
              :placeholder="$t ('label.pgpKeyLabel')"/>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <p v-if="errorMsg" class="red--text">
          {{ $t (errorMsg) }}
        </p>
        <v-btn
          v-t="'general.button.cancel'" text :disabled="isLoading"
          @click="onCancel"/>
        <v-btn
          v-t="'label.save'" color="primary" :loading="isLoading"
          @click="save"/>
      </v-card-actions>
    </v-card>
  </base-dialog>
</template>

<script>
  import {mapActions} from 'vuex'

  import BaseDialog from '@/app/core/components/BaseDialog'
  import CodeEditor from '@/app/core/components/CodeEditor/CodeEditor'

  export default {

    components: {
      CodeEditor,
      BaseDialog
    },

    props: {
      clientId: {
        type: Number,
        default: 0
      },
      value: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        isLoading: false,
        addDialog: false,
        newKey: '',
        error: false,
        errorMsg: null,
        lines: 200
      }
    },

    computed: {

    },

    watch: {
      value () {
        this.addDialog = this.value
        this.errorMsg = null
      },

      addDialog () {
        this.$emit ('input', this.addDialog)
        this.errorMsg = null
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      save () {
        this.errorMsg = null
        if (this.newKey === '') {
          this.error = true
        } else {
          this.error = false
          this.isLoading = true
          this.fetchData ({
            op: 'machine-clients/add-pgp-key',

            params: {
              id: this.clientId,
              pgpKey: this.newKey
            },
            cb: data => {
              this.$emit ('added', data.pgpData)
              this.reset ()
              this.addDialog = false
              this.errorMsg = null
            },
            cbError: (data, next) => {
              const result = data.result.find (item =>
                (item.code === 'error/datamgmt/value'))

              if (!result) {
                return next (data)
              }

              this.errorMsg = 'label.error.invalidPGPKey'
            },
            cbFinal: () => {
              this.isLoading = false
            }
          })
        }
      },

      reset () {
        this.newKey = ''
        this.error = false
      },

      onCancel () {
        this.addDialog = false
        this.reset ()
      }
    }

  }
</script>
