<messages>["./ManageMachineClients"]</messages>

<template>
  <base-dialog
    v-model="dialog"
    mw1>
    <template #activator="{ on }">
      <v-btn
        v-t="'label.showGatewayPgpKey'"
        v-on="on"/>
    </template>
    <v-card flat>
      <v-card-title primary-title>
        <div>
          <div
            class="text-h5"
            v-text="$t ('label.gatewayPgpKey')"/>
        </div>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col v-if="!isLoading && !error" cols="12">
            <v-card
              class="pa-2"
              flat
              color="grey lighten-2">
              <pre class="inlineCode">
                {{ gatewayKey }}
              </pre>
            </v-card>
          </v-col>
          <v-col v-else-if="isLoading" cols="12">
            <v-progress-linear
              indeterminate
              color="primary"/>
          </v-col>
          <v-col v-else-if="error" cols="12">
            <v-alert
              v-t="'label.error.loadGWKey'"
              type="error"/>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          v-t="'general.button.refresh'" text
          @click="loadKey"/>
        <v-btn
          v-t="'general.button.ok'" color="primary"
          @click="dialog=false"/>
      </v-card-actions>
    </v-card>
  </base-dialog>
</template>

<script>
  import {mapActions} from 'vuex'

  import BaseDialog from '@/app/core/components/BaseDialog'

  export default {
    components: {
      BaseDialog
    },
    data () {
      return {
        dialog: false,
        isLoading: false,
        gatewayKey: '',
        error: false
      }
    },
    watch: {
      dialog () {
        if (this.dialog && this.gatewayKey === '') {
          this.loadKey ()
        }
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      loadKey () {
        this.isLoading = true
        this.error = false
        this.fetchData ({
          op: 'machine-clients/load-gateway-pgp-key',
          cb: data => {
            this.gatewayKey = data.gatewayKey
          },
          cbCatch: () => {
            this.error = true
          },
          cbError: () => {
            this.error = true
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      }
    }
  }
</script>
